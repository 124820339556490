import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/Logo';
import FooterNav from './partials/FooterNav';
// import FooterSocial from './partials/FooterSocial';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

class Footer extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      topDivider,
      ...props
    } = this.props;

    const classes = classNames(
      'site-footer center-content-mobile',
      topOuterDivider && 'has-top-divider',
      className
    );

    return (
      <footer
        {...props}
        className={classes}
      >
        <div className="container">
          <div className={
            classNames(
              'site-footer-inner',
              topDivider && 'has-top-divider'
            )}>
            <div className="footer-top space-between text-xxs">
              <Logo />
              {/* <FooterSocial /> */}
            </div>
            <div className="footer-bottom space-between text-xxs invert-order-desktop">
              <FooterNav />
              <div className="footer-copyright">版权所有&copy; 2021 - 2024 上海麦凯盈科技有限公司</div>
            </div>
            <div className="footer-bottom space-between text-xxs invert-order-desktop">
              <nav class="footer-nav">
                <ul class="list-reset">
                  <li><a rel="noopener noreferrer" href='https://beian.miit.gov.cn/' target='_blank'>沪ICP备2022001909号</a></li>
                  {/* <li><a rel="noopener noreferrer" href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=' target='_blank'>沪安网安备 31011502018446号</a></li> */}
                  <li><a rel="noopener noreferrer" href='https://beian.miit.gov.cn/' target='_blank'>增值电信业务经营许可证：沪B2-20220953</a></li>
                  <li><a rel="noopener noreferrer" href='http://www.12377.cn/' target='_blank'>不良信息举报中心</a></li>
                  <li><a rel="noopener noreferrer" target="_blank" href="https://xyt.xcc.cn/getpcInfo?sn=1612689520883376128&certType=8&url=*.marketing-tech.cn&language=CN"><img src="https://program.xinchacha.com/xyt/xcc_small_ssl.png" alt="" style={{ width: '18px', highet: '18px' }} /></a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
